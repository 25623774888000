import emailjs from 'emailjs-com';
import ReactPlayer from 'react-player';

import { React, useRef, useState, useEffect } from 'react';
import { EnvelopeFill, PinMapFill, TelephoneFill } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';

// Begin Bootstrap Components
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Carousel from 'react-bootstrap/Carousel';
// End Bootstrap Components

import joinUsPic from '../../../img/AboutUs/JoinOurTrip.jpeg';
// Begin Images
import img1 from '../../../img/JoinUs/BridgetteHandingOutFood.JPG';
import img2 from '../../../img/JoinUs/HangingOutwithTeam.JPG';
import img3 from '../../../img/JoinUs/BridgetteManny.JPG';
// End Images

import './joinUs.css';

function Body() {
  const form = useRef();
  const [playerPause, setPlayerPause] = useState(true);

  useEffect(() => {
    const handleScroll = (event) => {
      if (window.scrollY > 100) {
        if (!playerPause) {
          setPlayerPause(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const videoGuatemala = 'https://vimeo.com/747473996';
  const playerHeight = '42vw'; // 800 px
  const playerWidth = '62.5vw'; // 1200 px

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('gmail', 'template_mpf7dvp', e.target, 'Z4AJtOVRkWhL8pEDT')
      .then(
        () => {
          toast.success('We appreciate your interest in Joining Us', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            className: 'custom-toast-message',
          });
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <Container>
        <Row className='d-flex justify-content-center'>
          <Col
            style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
            className='justify-content-center pt-2'
          >
            <h1 className='text-center'>Join Us on our next Adventure!</h1>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={10}
            lg={6}
            className='align-self-center pt-2'
          >
            <strong>Go and Build</strong> has committed to restructure several
            villages in Guatemala and with your help we can accomplish this.
            Please join us to fullfill our promise.
            <br></br>
            <br></br>
            We have partnered with vendors across the country to facilite our
            next trip. Put your group and team together and we will coordinate
            the transportation,room, materials, food and much more.
            <br></br>
            <br></br>
            There are no limits to what we can accomplish on our next trip.{' '}
            <strong>Go and Build</strong> has partnered with church groups,
            doctors, teachers, realtors and contruction groups. We have setup
            clinics for yearly checkups, brought in school supplies for schools,
            shared building techniques with our building teams, prayed as a
            large team and much more. At the end of our trip, we have immersed
            in local culture and enjoyed everything Guatemala has to offer.
            <br></br>
            <br></br>
            All Group and team building trips can be customizable!
          </Col>
          <Col
            xs={12}
            sm={12}
            md={10}
            lg={6}
            className='align-self-center pt-2'
          >
            <Image src={joinUsPic} style={{ width: '100%' }} alt='Join Us' />
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className='justify-content-center'>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className=' wow animate__animated animate__zoomIn animate__slower'
          >
            <ReactPlayer
              // className='react-player'
              url={videoGuatemala}
              playing={true}
              muted={false}
              width={playerWidth}
              height={playerHeight}
              controls={true}
              // onPause={playerPause}
            />
          </Col>
        </Row>
      </Container>

      <Container style={{ maxWidth: '1000px', minWidth: '400px' }}>
        <Row className='justify-content-center'>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Carousel>
              <Carousel.Item>
                <Image className='w-100' src={img2} alt='First slide' />
                {/* <Carousel.Caption>
                                <h3>First slide label</h3>
                                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                            </Carousel.Caption> */}
              </Carousel.Item>
              <Carousel.Item>
                <Image className='w-100' src={img1} alt='Second slide' />
              </Carousel.Item>
              <Carousel.Item>
                <Image className='w-100' src={img3} alt='Third slide' />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>

      <Container className='pt-5'>
        <Row>
          <Col>
            <h3>Create your Open Trip!</h3>
            <p className='text-left w-responsive mx-auto mb-5'>
              Thank you for your interest in joining us in our next trip! If you
              have any questions or will like to inquire how to create your own
              trip or would like us to create an open trip for other to join,
              please fill out the form below and a member of our team will
              respond to you shortly within 2 business days.
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form onSubmit={handleSubmit} ref={form}>
              <Row className='mb-3'>
                <Form.Group as={Col} controlId='firstName'>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control name='firstName' placeholder='First Name' />
                </Form.Group>

                <Form.Group as={Col} controlId='lastName'>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control name='lastName' placeholder='Last Name' />
                </Form.Group>
              </Row>

              <Form.Group className='mb-3' controlId='emailAddress'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name='emailAddress'
                  type='email'
                  placeholder='Enter email'
                />
                <Form.Text className='text-muted'>
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group className='mb-3' controlId='subject'>
                <Form.Label>Subject</Form.Label>
                <Form.Control name='subject' placeholder='Subject' />
              </Form.Group>

              <Form.Group className='mb-3' controlId='messageInput'>
                <Form.Label>Comment</Form.Label>
                <Form.Control name='messageInput' as='textarea' rows={3} />
              </Form.Group>

              <Row>
                <Col xs lg='4'>
                  <Button className='mb-3' variant='primary' type='submit'>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col>
            <ListGroup className='borderless border border-0'>
              <ListGroup.Item className='mb-3 border border-0'>
                <TelephoneFill className='col-1' /> Phone: + 1 909 766 0445
              </ListGroup.Item>
              <ListGroup.Item className='mb-3 border border-0'>
                <PinMapFill className='col-1' />
                Address: 10 W Bay State Street #1274, Alhambra CA 91802, USA
              </ListGroup.Item>
              <ListGroup.Item className='mb-3 border border-0'>
                <EnvelopeFill className='col-1' />
                Email: <u style={{ color: 'blue' }}>partner@goandbuild.org</u>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Body;
