import { React } from 'react';

// Begin Bootstrap Components
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
// End Bootstrap Components

// images
import joseFounder from '../../../img/Founders/JoseFounder.jpeg';
import mannyFounder from '../../../img/Founders/MannyFounder.jpeg';
import noeFounder from '../../../img/Founders/NoeLesterFounder.jpeg';
//End Image Line

function Body() {
  return (
    <>
      <Container>
        <Row className=' d-flex justify-content-center'>
          <Col className='text-center'>
            <h1
              className='my-1 my-lg-2'
              style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
            >
              Get to know the Founders
            </h1>

            <div style={{ margin: 'auto' }} className='pt-1 pt-lg-2'>
              <h5>
                We hope what inspired us, will inspire you and join us. Together
                we can make a difference rebuild the lives of those in need.
              </h5>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Begin Jose Founder */}
      <Container>
        <Row>
          <Col xs={12} sm={12} md={10} lg={6} className='align-self-center'>
            <div className='mx-2'>
              <h1 className='my-3 d-none d-lg-block'>Jose Leiva</h1>
              <h1
                className='text-center my-3  d-xs-block d-lg-none'
                style={{ background: '#000000', color: '#fff' }}
              >
                Jose Leiva
              </h1>

              <h5>
                Founder and general director on the Guatemala side.
                <br></br>
                JD oversees the daily operations on the field. He has been
                working on the social development area in Guatemala for more
                than 10 years, graduated in management in social work, JD has a
                pasión for management, logistics and coordination, triathlons,
                futbol, languages and is always looking for new challenges.
              </h5>
            </div>
          </Col>

          <Col xs={12} sm={12} md={10} lg={6} className='align-self-center'>
            <Image style={{ width: '100%' }} src={joseFounder} />
          </Col>
        </Row>
      </Container>
      {/* Begin Jose Founder */}

      {/* Begin Manny Founder */}
      <Container className='pt-5'>
        <Row>
          <Col
            xs={{ span: 12, order: 'last' }}
            sm={{ span: 12, order: 'last' }}
            md={{ span: 10, order: 'first' }}
            lg={{ span: 6, order: 'first' }}
            className='align-self-center order-xs-last order-sm-last'
          >
            <Image style={{ width: '100%' }} src={mannyFounder} />
          </Col>
          <Col
            xs={{ span: 12, order: 'first' }}
            sm={{ span: 12, order: 'first' }}
            md={{ span: 10, order: 'last' }}
            lg={{ span: 6, order: 'last' }}
            className='align-self-center order-xs-first order-sm-first'
          >
            <div className='mx-2'>
              <h1 className='my-3 d-none d-lg-block'>Manny Gomez</h1>
              <h1
                className='text-center my-3  d-xs-block d-lg-none'
                style={{ background: '#000000', color: '#fff' }}
              >
                Manny Gomez
              </h1>

              <h5>
                Founder and finance director in the United States.
                <br></br>
                Manny manages all the financial aspects of operating a
                non-profit. His first trip to Guatemala was an experience of a
                life time. The culture was that to admire. However, he was
                troubled by what the tourists do not see. The economic
                instability within the families being the norm. Inspired by the
                hard work of founder JD, he knew together we can reach and
                inpact so many lives.
              </h5>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Begin Manny Founder */}

      {/* Begin Noe Founder */}
      <Container className='pt-5'>
        <Row>
          <Col xs={12} sm={12} md={10} lg={6} className='align-self-center'>
            <div className='mx-2'>
              <h1 className='my-3 d-none d-lg-block'>Noe Leiva</h1>
              <h1
                className='text-center my-3  d-xs-block d-lg-none'
                style={{ background: '#000000', color: '#fff' }}
              >
                Noe Leiva
              </h1>

              <h5>
                Founder and general director in the United States.
                <br></br>
                Noe oversees the operations and communications in the United
                States. Born in the United States but spent over 6 years living
                in Guatemal, was troubled by the situation in Guatemala. The
                limited access to food, employment, and overall opportunity, Noe
                knew there was something he had to do about it.
              </h5>
            </div>
          </Col>

          <Col xs={12} sm={12} md={10} lg={6} className='align-self-center'>
            <Image style={{ width: '100%' }} src={noeFounder} />
          </Col>
        </Row>
      </Container>
      {/* Begin Noe Founder */}
    </>
  );
}

export default Body;
