import React from 'react'
import './WhatWeDo.css'

import Body from './Body'

function Index() {
  return (
    <div>
      <Body />
    </div>
  )
}

export default Index
