import React from 'react'

import Body from './Body'

function ContactUs() {
  return (
    <>
      <Body />
    </>
  )
}

export default ContactUs
