import React from 'react'

import Body from './Body'

function AboutUs() {
  return (
    <>
      <Body />
    </>
  )
}

export default AboutUs
