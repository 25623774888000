import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useAuth } from '../AuthContext/AuthContext';

// Begin Bootstrap Components
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// End Bootstrap Components

import logo from '../../media/GoAndBuildLogo_20220817.png';

import './services.css';

function NavScrollExample() {
  const [user, setUser] = useState({});
  const auth = useAuth();

  const imageStyle = {
    marginTop: '-1vw',
    marginBottom: '-2vw',
    width: '16vh',
    height: '16vh',
  };

  useEffect(() => {
    setUser(auth.user);
  });
  
  return (
    <Navbar fixed='top' collapseOnSelect bg='light' expand='lg'>
      <Container>
        <Navbar.Brand to='#home'>
          <Nav.Link as={Link} style={imageStyle} eventKey='link-1' to='/'>
            <img style={imageStyle} src={logo} />
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='navbarScroll' />
        <Navbar.Collapse id='navbarScroll'>
          <Nav
            className='me-auto my-2 my-lg-0'
            style={{ maxHeight: '100px', fontSize: '1.25vw' }}
            navbarScroll
          >
            <Nav.Link
              className='nav-link thumbnail'
              as={NavLink}
              eventKey='link-3'
              to='/AboutUs/joinus'
            >
              <h6>JOIN US</h6>
            </Nav.Link>
            <Nav.Link
              className='nav-link thumbnail'
              as={NavLink}
              eventKey='link-2'
              to='/WhatWeDo'
            >
              <h6>WHAT WE DO</h6>
            </Nav.Link>
            <Nav.Link
              className='nav-link thumbnail'
              as={NavLink}
              eventKey='link-3'
              to='/OurWork'
            >
              <h6>OUR WORK</h6>
            </Nav.Link>
            <Nav.Link
              className='nav-link thumbnail'
              as={NavLink}
              eventKey='link-3'
              to='/AboutUs'
            >
              <h6>ABOUT US</h6>
            </Nav.Link>
            {!auth.user ? (
              <Nav.Link
                className='nav-link thumbnail'
                as={NavLink}
                eventKey='link-3'
                to='/ContactUs'
              >
                <h6>CONTACT US</h6>
              </Nav.Link>
            ) : (
              <Nav.Link
                className='nav-link thumbnail'
                as={NavLink}
                eventKey='link-3'
                to='/AboutUs/editSponsorship'
              >
                <h6>SPONSORSHIP</h6>
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
        <Nav className='text-center pt-md-2 d-none d-sm-none d-md-none'>
          <div givebutter-element-id='xLvdap'></div>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default NavScrollExample;
